/** @jsx jsx */
import {jsx} from 'theme-ui';
import React, {useCallback, useEffect, useRef} from 'react'
import {useMeasure} from 'react-use';
import Konva from 'konva';
import {Stage, Layer, Image, Text, Group} from 'react-konva';
import useImage from 'use-image';
import colors from "../@elegantstack/flow-ui-theme/theme/colors";

Konva.hitOnDragEnabled = true;


const styles = {
    container: {
        height: '60vh',
        width: '100%',
        cursor: 'grab',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    map: {
        canvas: {
            marginTop: '15px',
            backgroundColor: 'background',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '800px',
            backgroundPositionX: '400px',
            backgroundPositionY: '80px'
        },
        image: {
            cursor: 'pointer',
            borderRadius: '50%'
        },
        text: {
            fontWeight: 'bold',
            fontSize: '0.1rem',
            fill: 'alpha',
            cursor: 'pointer',
            ':hover': {
                fill: 'alphaDark'
            }
        }
    }
};

const DogBreedImage = React.memo(({href, ...others}) => {
    const [image] = useImage(href);
    return <Image image={image} {...others} />;
});

const getDistance = (p1, p2) => Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));

const DogBreedExplorerMap = ({
                                 maxScale = 1.5, minScale = 0.05,
                                 initialScale = 0.2, imageSize = 200,
                                 dogBreedData, baseScale = 60000.0,
                                 dragSpeed = 5.0, scrollScaleBy = 2.0, selectedBreedName = undefined
                             }) => {
    const [containerRef, containerMeasurements] = useMeasure();
    const [data, canvasHeight, canvasWidth] = [
        dogBreedData, containerMeasurements.height, containerMeasurements.width];
    const stageRef = useRef(undefined);

    const minX = Math.min(...data.map(x => x.position.x));
    const maxX = Math.max(...data.map(x => x.position.x));
    const minY = Math.min(...data.map(x => x.position.y));
    const maxY = Math.max(...data.map(x => x.position.y));

    initialScale = Math.min(Math.max(initialScale, minScale), maxScale);

    const createBoundFunc = useCallback((scale = undefined) => (pos) => {
        const stage = stageRef.current;
        if (!stage || canvasWidth === 0 || canvasHeight === 0) {
            return pos;
        }

        const scaleX = scale?.scaleX ?? stage.scaleX();
        const scaleY = scale?.scaleY ?? stage.scaleY();

        const paddingX = imageSize * scaleX;
        const paddingY = imageSize * scaleY;

        const actualWidth = (maxX - minX) * baseScale * scaleX + imageSize * scaleX;
        const actualHeight = (maxY - minY) * baseScale * scaleY + imageSize * scaleY;

        let rightBound = Math.min(canvasWidth - actualWidth - paddingX, actualWidth - paddingX);
        let leftBound = Math.min(paddingX, actualWidth - canvasWidth + paddingX);
        let bottomBound = Math.min(canvasHeight - actualHeight - paddingY, actualHeight - paddingY);
        let topBound = Math.min(paddingY, actualHeight - canvasHeight + paddingY);

        return {
            x: Math.max(Math.min(pos.x, leftBound), rightBound),
            y: Math.max(Math.min(pos.y, topBound), bottomBound)
        };
    }, [minX, maxX, minY, maxY, baseScale, canvasWidth, canvasHeight, imageSize]);

    const zoomToDogBreedPosition = useCallback((position, newScale, duration = 1.0) => {
        const stage = stageRef.current;
        if (!stage) {
            return undefined;
        }

        const oldScale = stage.scaleX();

        const origPosX = (position.x - minX) * baseScale;
        const scaledX = (origPosX * stage.scaleX() + stage.x())

        const origPosY = (position.y - minY) * baseScale;
        const scaledY = (origPosY * stage.scaleY() + stage.y())

        const newPosition = createBoundFunc({scaleX: newScale, scaleY: newScale})({
            x: ((stage.x() - scaledX) / oldScale + canvasWidth / 3 - imageSize / 2) * newScale,
            y: ((stage.y() - scaledY) / oldScale + canvasHeight / 3 - imageSize / 2) * newScale,
        });

        stage.to({
            scaleX: newScale,
            scaleY: newScale,
            ...newPosition,
            easing: Konva.Easings.EaseInOut,
            duration: duration
        });
    }, [minX, minY, baseScale, canvasWidth, canvasHeight, imageSize, createBoundFunc]);

    useEffect(() => {
        const stage = stageRef.current
        if (!stage) {
            return;
        }

        stage.lastTouchDist = 0.0;
        stage.speed = {x: 0.0, y: 0.0};
        stage.lastPos = {x: 0.0, y: 0.0};
        stage.lastTouchCenter = undefined;

        if (selectedBreedName) {
            let breedData = data.find(x => x.name === selectedBreedName);
            if (!breedData) {
                return;
            }

            zoomToDogBreedPosition(breedData.position, maxScale);
        } else {
            zoomToDogBreedPosition({x: minX + (maxX - minX) / 5, y: minY + (maxY - minY) / 2}, initialScale);
        }
    }, [stageRef, initialScale, minX, minY, maxX, maxY, zoomToDogBreedPosition, selectedBreedName, data, maxScale]);

    return (
        <div className="dog-breed-explorer-container"
             ref={containerRef}
             sx={styles.container}
        >
            <Stage width={canvasWidth} height={canvasHeight} sx={styles.map.canvas}
                   ref={stageRef}
                   draggable={true}
                   scaleX={initialScale}
                   scaleY={initialScale}
                   onWheel={ev => {
                       if (!ev?.evt) {
                           return;
                       }

                       ev.evt.preventDefault();

                       const stage = ev.target.getStage();
                       const oldScale = stage.scaleX();
                       const mousePointTo = {
                           x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
                           y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
                       };

                       const newScale = Math.max(Math.min(ev.evt.deltaY < 0 ? oldScale * scrollScaleBy : oldScale / scrollScaleBy, maxScale), minScale);
                       const newPosition = createBoundFunc({scaleX: newScale, scaleY: newScale})({
                           x: stage.getPointerPosition().x - mousePointTo.x * newScale,
                           y: stage.getPointerPosition().y - mousePointTo.y * newScale
                       });

                       stage.to({
                           scaleX: newScale,
                           scaleY: newScale,
                           ...newPosition
                       })
                   }}
                   onTouchMove={(ev) => {
                       if (!ev?.evt) {
                           return;
                       }

                       ev.evt.preventDefault();

                       const stage = stageRef.current;
                       if (!stage) {
                           return;
                       }

                       const touch1 = ev.evt.touches?.[0];
                       const touch2 = ev.evt.touches?.[1];

                       if (!touch1 || !touch2) {
                           // Single touch, set data for smooth dragging
                           if (stage.lastPos !== undefined) {
                               const pos = stage.position();
                               stage.speed.x = pos.x - stage.lastPos.x;
                               stage.speed.y = pos.y - stage.lastPos.y;
                               stage.lastPos = createBoundFunc()(pos);
                           }

                           return;
                       } else {
                           stage.lastPos = undefined;
                       }

                       // if (stage.isDragging()) {
                       //     stage.stopDrag();
                       // }

                       const content = stage.getContent();
                       const p1 = {
                           x: (touch1.clientX - content.offsetLeft),
                           y: (touch1.clientY - content.offsetTop)
                       };
                       const p2 = {
                           x: (touch2.clientX - content.offsetLeft),
                           y: (touch2.clientY - content.offsetTop)
                       }

                       const oldScale = stage.scaleX();
                       // const newCenter = getCenter(p1, p2);
                       const newCenter = stage.getPointerPosition();

                       if (!stage.lastTouchCenter) {
                           stage.lastTouchCenter = newCenter;
                           return;
                       }

                       const dist = getDistance(p1, p2);
                       if (dist > 0) {
                           if (!stage.lastTouchDist) {
                               stage.lastTouchDist = dist;
                               return;
                           }

                           const increaseScaleBy = stage.lastTouchDist > 0.0 && dist > 0.0 ? dist / stage.lastTouchDist : 1.0;
                           let newScale = oldScale * increaseScaleBy;
                           newScale = Math.min(Math.max(newScale, minScale), maxScale);

                           // local coordinates of center point
                           const pointTo = {
                               x: (newCenter.x - stage.x()) / oldScale,
                               y: (newCenter.y - stage.y()) / oldScale,
                           };

                           // calculate new position of the stage
                           const dx = (newCenter.x - stage.lastTouchCenter.x);
                           const dy = (newCenter.y - stage.lastTouchCenter.y);

                           const newPosition = createBoundFunc({scaleX: newScale, scaleY: newScale})({
                               x: newCenter.x - pointTo.x * newScale + dx,
                               y: newCenter.y - pointTo.y * newScale + dy
                           });

                           stage.scaleX(newScale);
                           stage.scaleY(newScale);
                           stage.position(newPosition);
                       }

                       stage.lastTouchDist = dist;
                       stage.lastTouchCenter = newCenter;
                   }}
                   onTouchStart={(ev) => {
                       if (!ev?.evt) {
                           return;
                       }

                       ev.evt.stopPropagation();

                       const stage = stageRef.current;
                       if (!stage) {
                           return;
                       }

                       const touch1 = ev.evt.touches?.[0];
                       const touch2 = ev.evt.touches?.[1];

                       // Single touch, set last pos for smooth dragging.
                       if (!touch1 || !touch2) {
                           stage.lastPos = createBoundFunc()(stage.position());
                       } else {
                           stage.lastPos = undefined;

                           stage.draggable(false);
                       }
                   }}
                   onTouchEnd={(ev) => {
                       if (!ev?.evt) {
                           return;
                       }

                       ev.evt.stopPropagation();

                       const stage = stageRef.current;
                       if (!stage) {
                           return;
                       }

                       stage.lastTouchDist = 0.0;
                       stage.lastTouchCenter = undefined;

                       if (stage.lastPos !== undefined) {
                           const newPosition = createBoundFunc()({
                               x: stage.x() + stage.speed.x * dragSpeed,
                               y: stage.y() + stage.speed.y * dragSpeed,
                           });

                           stage.to({
                               ...newPosition,
                               easing: Konva.Easings.EaseOut,
                               duration: 0.5
                           });

                           stage.lastPos = undefined;
                       }

                       stage.draggable(true);
                   }}
                   onDragStart={(ev) => {
                       ev.evt.preventDefault();

                       const stage = stageRef.current;
                       if (!stage) {
                           return;
                       }

                       stage.lastPos = createBoundFunc()(stage.position());
                   }}
                   onDragMove={(ev) => {
                       if (!ev?.evt) {
                           return;
                       }

                       ev.evt.preventDefault();

                       const stage = stageRef.current;
                       if (!stage) {
                           return;
                       }

                       if (stage.lastPos !== undefined) {
                           const pos = stage.position();
                           stage.speed.x = pos.x - stage.lastPos.x;
                           stage.speed.y = pos.y - stage.lastPos.y;
                           stage.lastPos = createBoundFunc()(pos);
                       }
                   }}
                   onDragEnd={(ev) => {
                       if (!ev?.evt) {
                           return;
                       }

                       ev.evt.preventDefault();
                       ev.evt.stopPropagation();

                       const stage = stageRef.current;
                       if (!stage) {
                           return;
                       }

                       const newPosition = createBoundFunc()({
                           x: stage.x() + stage.speed.x * dragSpeed,
                           y: stage.y() + stage.speed.y * dragSpeed,
                       });

                       stage.to({
                           ...newPosition,
                           easing: Konva.Easings.EaseOut,
                           duration: 0.5
                       });

                       stage.lastPos = undefined;
                   }}
                   dragBoundFunc={createBoundFunc()}
            >
                <Layer>
                    {
                        data.map((breedData) => {
                                const openLink = (ev) => {
                                    const touch1 = ev?.evt?.touches?.[0];
                                    const touch2 = ev?.evt?.touches?.[1];

                                    if (touch1 && touch2) {
                                        return;
                                    }

                                    if (breedData?.link) {
                                        window?.open(breedData.link, '_blank')?.focus();
                                    }
                                }
                                const changeStageCursor = cursorStyle => e => {
                                    if (!breedData.link) {
                                        return;
                                    }

                                    const container = e.target.getStage().container();
                                    container.style.cursor = cursorStyle;
                                };
                                return (
                                    <Group key={breedData.name}
                                           x={(breedData.position.x - minX) * baseScale}
                                           y={(breedData.position.y - minY) * baseScale}>
                                        <DogBreedImage
                                            width={imageSize} height={imageSize}
                                            href={breedData.image}
                                            sx={styles.map.image}
                                            onClick={openLink}
                                            onDblTap={openLink}
                                            onMouseEnter={changeStageCursor('pointer')}
                                            onMouseLeave={changeStageCursor('grab')}
                                        />
                                        <Text x={0} y={imageSize + 10}
                                              width={imageSize}
                                              sx={styles.map.text}
                                              onClick={openLink}
                                              onMouseEnter={e => {
                                                  if (!breedData.link) {
                                                      return;
                                                  }

                                                  changeStageCursor('pointer')(e);

                                                  e.target.to({
                                                      'fill': colors.alphaDark,
                                                      duration: 0.05
                                                  })
                                              }}
                                              onMouseLeave={e => {
                                                  if (!breedData.link) {
                                                      return;
                                                  }

                                                  changeStageCursor('grab')(e);

                                                  e.target.to({
                                                      'fill': colors.alpha,
                                                      duration: 0.05
                                                  })
                                              }}
                                              text={breedData.name}
                                              fill={colors.alpha}
                                              align="center"
                                        />
                                    </Group>
                                );
                            }
                        )
                    }
                </Layer>
            </Stage>
        </div>
    );
};

export default DogBreedExplorerMap;
