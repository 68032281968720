import React, {useEffect, useRef, useState} from 'react'
import {Stack, Main, Layout} from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import {Heading, Select, Text, Box, IconButton, Spinner, css} from 'theme-ui'
import DogBreedExplorerMap from "../dogBreedExplorer/DogBreedExplorerMap";
import {FaInfoCircle} from 'react-icons/fa'

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
        maxWidth: ['100%', '100%', '75%', '75%'],
    },
    dogBreedListContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        maxWidth: ['100%', '100%', '75%', '75%'],
        paddingTop: '50px'
    },
    h1: {
        textAlign: 'center',
    },
    instructionText: {
        textAlign: 'center',
        fontStyle: 'italic',
    },
    copyrightNotice: {
        paddingTop: '1rem',
        textAlign: 'center',
        fontStyle: 'italic',
    },
    pitch: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.25rem',
    },
    error: {
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: '1.25rem',
    },
    select: {
        width: 'auto',
        alignItems: 'center',
        marginTop: '1rem'
    },
    breedList: {
        fontWeight: 'normal',
        fontSize: '1.25rem',
    },
    breedListItem: {
        lineBreak: 'auto'
    },
    breedListItemShowOnMap: {
        cursor: 'pointer',
        color: 'alpha',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    breedListItemGoToInfo: {
        cursor: 'pointer',
        paddingLeft: '15px',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
};

const DogBreedExplorerPage = props => {
    const [selectedBreedName, setSelectedBreedName] = useState(undefined);
    const [dogBreedData, setDogBreedData] = useState(undefined);
    const [dogBreedDataState, setDogBreedDataState] = useState('loading');
    const selectRef = useRef();

    useEffect(() => {
        import('../dogBreedExplorer/dog_breed_data.json')
            .then(({default: dogBreedData}) => {
                setDogBreedData(dogBreedData);
                setDogBreedDataState('done');
            })
            .catch(err => {
                setDogBreedDataState('error');
            })
    });

    return (
        <Layout {...props}>
            <Seo title='Dog Breed Explorer'/>
            <Divider/>
            <Stack>
                <Main>
                    <Box sx={styles.container}>
                        <Heading variant='h1' as='h1' sx={styles.h1}>
                            Dog Breed Explorer
                        </Heading>

                        {dogBreedDataState === 'loading'
                            ? <Spinner title="Loading dog breed map..."/>
                            : null
                        }

                        {dogBreedDataState === 'done' && dogBreedData !== undefined ?
                            <>
                                <Text variant='h2' sx={styles.pitch}>
                                    Explore 500+ dog breeds to find your next dog.
                                </Text>
                                <Text variant='p' sx={styles.instructionText}>
                                    On mobile: Pan using one finger, zoom using two fingers, and double-tap to open
                                    breed
                                    info.<br/>
                                    On desktop: Pan by pressing down the left mouse button, zoom using the mouse wheel,
                                    and
                                    click to open breed info.</Text>
                                <Select ref={selectRef}
                                        initialValue=""
                                        aria-label="Zoom on Breed"
                                        sx={styles.select}
                                        value={selectedBreedName}
                                        onChange={(ev) => {
                                            const breed = ev.target.value;
                                            if (!breed) {
                                                return;
                                            }

                                            setSelectedBreedName(breed);
                                        }}>
                                    <option aria-label="None"></option>
                                    {
                                        dogBreedData.map((breedData) => <option key={breedData.name}
                                                                                aria-label={breedData.name}
                                                                                value={breedData.name}>{breedData.name}</option>)
                                    }
                                </Select>
                            </>
                            : (dogBreedDataState === 'error'
                                    ?
                                    <Text variant='p' sx={styles.error}>
                                        We're sorry, but we were unable to load the map. Please refresh to try again.
                                    </Text>
                                    : null
                            )
                        }
                    </Box>
                </Main>
            </Stack>
            {dogBreedDataState === 'done' && dogBreedData !== undefined ?
                <>
                    <DogBreedExplorerMap dogBreedData={dogBreedData} selectedBreedName={selectedBreedName}/>
                    <Text variant='p' sx={styles.copyrightNotice}>
                        All images were taken from Wikipedia/Google.
                    </Text>

                    <Stack>
                        <Box sx={styles.dogBreedListContainer}>
                            <Heading variant='h2' as='h2' sx={styles.h2}>
                                Dog Breed List
                            </Heading>
                            <Text variant='p' sx={styles.instructionText}>
                                Click on a dog breed to show on map. You can use the info button to know more about the
                                breed.
                            </Text>
                            <ol css={css(styles.breedList)}>
                                {
                                    dogBreedData.map((dogBreed) => <li style={styles.breedListItem}
                                                                       key={dogBreed.name}>
                                        <div css={css(styles.breedListItemShowOnMap)} onClick={() => {
                                            setSelectedBreedName(dogBreed.name);
                                            selectRef.current?.scrollIntoView({behavior: 'smooth'})
                                        }}>
                                            {dogBreed.name}
                                            <IconButton variant="secondary" color="omega"
                                                        onClick={() => {
                                                            window.open(dogBreed.link, '_blank').focus();
                                                        }}
                                                        css={css(styles.breedListItemGoToInfo)}>
                                                <FaInfoCircle/>
                                            </IconButton>
                                        </div>
                                    </li>)
                                }
                            </ol>
                        </Box>
                    </Stack>
                </>
                : null
            }
        </Layout>
    )
}

export default DogBreedExplorerPage;